import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import {
  RouterLink,
  RouterLinkActive,
  RouterModule,
  RouterOutlet,
} from '@angular/router';
import { AdventuresComponent } from './adventures.component';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TranslocoModule } from '@ngneat/transloco';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  declarations: [AdventuresComponent],
  imports: [
    CommonModule,
    TranslocoModule,
    SharedModule,
    RouterOutlet,
    RouterLink,
    RouterLinkActive,
    MatButtonModule,
    MatIconModule,
    RouterModule.forChild([
      {
        path: '',
        pathMatch: 'full',
        component: AdventuresComponent,
      }
     
    ]),
    
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AdventuresModule {}
