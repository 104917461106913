import { Component, WritableSignal, signal } from '@angular/core';
import { AsyncPipe, CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { TranslocoModule } from '@ngneat/transloco';
import { MatFormFieldModule } from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { FilterService } from '../_services/filters.service';
import { MetadataService } from '../_services/metadata.service';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-filters',
  standalone: true,
  imports: [CommonModule, SharedModule, TranslocoModule,MatButtonModule, MatFormFieldModule, MatDialogModule, MatInputModule,ReactiveFormsModule, MatAutocompleteModule,MatSelectModule, AsyncPipe, MatIconModule],
  templateUrl: './filters.component.html',
  styleUrl: './filters.component.scss'
})
export class FiltersComponent {
  
  filteredOptions : WritableSignal<any[]> = signal([]);

  constructor(public filterService : FilterService, public metadataService : MetadataService){}
  
  
  ngOnInit() {
    this.filteredOptions.set(this.metadataService.miscMetadata().countryCitiesMap[0].cities);
    this.filterService.city?.valueChanges.subscribe(s =>{
      const filtered = this.metadataService.miscMetadata().countryCitiesMap[0].cities.filter(option => { return option.value.toLowerCase().includes(s)});
      this.filteredOptions.set(filtered);
    })
  }
}
