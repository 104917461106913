import { AfterViewInit, Component, OnInit } from '@angular/core';
import { MetadataService } from '../_services/metadata.service';
import { FilterService } from '../_services/filters.service';

import { MatDialog } from '@angular/material/dialog';
import { FiltersComponent } from '../filters/filters.component';
import { UsersService } from '../_services/users.service';
// import testJson from '../../assets/testJson.json'

@Component({
  selector: 'app-adventures',
  templateUrl: './adventures.component.html',
  styleUrl: './adventures.component.scss',
})
export class AdventuresComponent implements OnInit {

  public breakpoints = {
    320: {
      slidesPerView: '4',
    },
    600: {
      slidesPerView: '5',
    },
    900: {
      slidesPerView: '7',
    },
  };
  constructor(
    public metadataService: MetadataService,
    public userService: UsersService,
    private _filterService: FilterService,
    public dialog: MatDialog,
  ) {}

  ngOnInit() {
    if (!this.userService.list) this.userService.getUsers();
    if (!this.userService.promotedList) this.userService.getPromotedUsers();
  }

  openDialog() {
    const dialogRef = this.dialog.open(FiltersComponent, {
      data: {
        animal: 'panda',
      },
      panelClass: 'custom-dialog',
    });

    dialogRef.afterClosed().subscribe((result) => {
      switch (result) {
        case result === null: {
          this.clearFilters();
          this.userService.getUsers();
          break;
        }
        case result === true: {
          this.userService.submittedIds = [];
          if (this._filterService.city.value) this.getCityFromValue();
          this.userService.getUsers();
          break;
        }
      }
    });
  }

  clearFilters() {
    this._filterService.city?.setValue([]);
    this._filterService.filterForm.get('minAge')?.setValue(18);
    this._filterService.filterForm.get('maxAge')?.setValue(60);
    this._filterService.filterForm.get('genders')?.setValue([]);
    this._filterService.filterForm.get('cities')?.setValue([]);
    this._filterService.filterForm.get('firstName')?.setValue('');

    this._filterService.filterForm?.markAsPristine();
    this._filterService.city?.markAsPristine();
  }

  getCityFromValue() {
    const id = this.metadataService
      .miscMetadata()
      .countryCitiesMap[0].cities.find(
        (x) => x.value === this._filterService.city.value
      )?.id;
    this._filterService.filterForm.get('cities')?.patchValue([id]);
  }

  loadMore() {
    this.userService.getUsers();
  }
}
