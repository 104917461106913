<div class="container">
  <div class="header">
    <h1>{{ "app.escorts" | transloco }}</h1>
    <button (click)="openDialog()" mat-fab extended>
      <mat-icon>filter_list</mat-icon>{{ "app.filters" | transloco }}
    </button>
  </div>
  @if(userService.promotedList){ 
    @if(userService.promotedList?.length){
  <div class="promoted-container">
    <swiper-container #swiper slides-per-view="4.4" [breakpoints]="breakpoints" navigation="true">
      @for(user of userService.promotedList;track user.id){
      <swiper-slide>
        <a
          class="promoted-card"
          [href]="user.user.url"
          [routerLink]="user.user.url"
        >
          <div class="avatar">
            <img
              alt="escorte sexy in orasul tau  {{
                user.user.userDetails.cityId | cityPipe
              }}"
              [src]="user.profilePhoto.data"
            />
          </div>
          <p class="text-stories">{{ user.user.userDetails.firstName }}</p>
        </a>
      </swiper-slide>
      }
    </swiper-container>
  </div>
  } } @if(userService.list?.length){
  <div class="users-container">
    <div class="sub-container">
      @for(user of userService.list;track user.id){
      <a [href]="user.user.url" [routerLink]="user.user.url" class="user-card">
        <img
          alt="escorte sexy escorte {{
            user.user.userDetails.cityId | cityPipe
          }}"
          [src]="user.profilePhoto.data"
        />
        <div class="image">
          <div class="gradient">
            <div class="content">
              <h3>
                {{ user.user.userDetails.firstName }} ({{
                  metadataService.getYears(user.user.userDetails.dob)
                }})
              </h3>

              <p>
                {{ user.user.userDetails.cityId | cityPipe }}
              </p>
            </div>
          </div>
        </div>
      </a>
      }
    </div>
    @if(userService.showLoadMore()){
    <button mat-stroked-button class="view-more-button" (click)="loadMore()">
      <mat-icon>keyboard_arrow_down</mat-icon>{{ "app.loadMore" | transloco }}
    </button>
    }
  </div>
  } @else {
  <div class="no-results">
    <h2>{{ "app.noResults" | transloco }}</h2>
  </div>
  }
</div>
