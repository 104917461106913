<div class="title" mat-dialog-title>
  <p>{{ "app.filters" | transloco }}</p>
  <button mat-mini-fab mat-dialog-close="">
    <mat-icon>close</mat-icon>
  </button>
</div>
<div mat-dialog-content class="content">
  <form [formGroup]="filterService.filterForm">
    <mat-form-field>
      <mat-label>{{ "form.firstName" | transloco }}</mat-label>
      <input formControlName="firstName" matInput />
    </mat-form-field>
    <mat-form-field>
      <mat-label>{{ "form.city" | transloco }}</mat-label>
      <input
        type="text"
        matInput
        [formControl]="filterService.city"
        [matAutocomplete]="auto"
      />
      <mat-autocomplete #auto="matAutocomplete">
        @for (city of filteredOptions();track city) {
        <mat-option [value]="city.value">{{ city.value }}</mat-option>
        }
      </mat-autocomplete>
    </mat-form-field>
    <div class="age">
      <mat-form-field>
        <mat-label>{{ "form.minAge" | transloco }}</mat-label>
        <input type="number" formControlName="minAge" matInput />
      </mat-form-field>
      <mat-form-field>
        <mat-label>{{ "form.maxAge" | transloco }}</mat-label>
        <input type="number" formControlName="maxAge" matInput />
      </mat-form-field>
    </div>
    <mat-form-field>
      <mat-label>{{ "form.gender" | transloco }}</mat-label>
      <mat-select formControlName="genders" multiple>
        @for (gender of
        metadataService.miscMetadata().userDetails.genderId.values;track gender)
        {
        <mat-option [value]="gender.id">{{ gender.value }}</mat-option>
        }
      </mat-select>
    </mat-form-field>
  </form>
</div>
<div mat-dialog-actions class="buttons">
  <button mat-button [mat-dialog-close]="false">
    {{ "app.clearFilters" | transloco }}
  </button>

  <button mat-fab extended [mat-dialog-close]="true">
    {{ "app.filterNow" | transloco }}
  </button>
</div>
